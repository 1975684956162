import React, { useState, useEffect } from 'react';
import { useHistory,useLocation } from 'react-router-dom';
import Header from '../components/ui/Header';
import HeroSection from '../components/ui/HeroSection';
import QuestList from '../components/ui/QuestList';
import styled from 'styled-components';
import CreateUniverseModal from '../components/ui/CreateUniverseModal';
import HeaderIcon from 'assets/Group 14315.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AppContainer = styled.div`
  min-height: 100vh;
  max-width: 640px;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
`;

const Logo = styled.div`
  position: fixed;
  top: 40px;
  left: ${props => (props.leftPosition + 10)}px;
  z-index: 1000;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: ${props => props.show ? 1 : 0};
  transform: translateY(${props => props.show ? '0' : '-20px'});
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: ${props => props.show ? 'auto' : 'none'};

  @media (max-width: 640px) {
    top: 35px;
    left: 20px;
  }
`;

const LogoImage = styled.img`
  height: 50px;
  width: auto;

  @media (max-width: 640px) {
    height: 40px;
  }
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CreateButton = styled(Button)`
background-color: #9eff00;
color: #000;
border-radius: 12px;
border: 1px solid #9eff00;
width: calc(100% - 40px); // Adjust width with padding from edges
max-width: 190px; // Ensure button doesn't get too wide
padding: 0.5rem 1rem;

position: fixed; // Fix the button to the viewport
bottom: 20px; // Position it at the bottom of the viewport
left: 50%; // Center horizontally
transform: translateX(-50%); // Adjust centering

z-index: 1000;  // Ensure button doesn't get too wide

  @media (min-width: 768px) {
  }
`;

const ContentWrapper = styled.div`
  max-width: 640px;
  margin: 0 auto;
  box-sizing: border-box;
`;

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [logoLeftPosition, setLogoLeftPosition] = useState(20);
  const history = useHistory();
  const location = useLocation(); 


  // Calculate logo position on mount and window resize
  useEffect(() => {
    const calculateLogoPosition = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth > 640) {
        const leftOffset = (windowWidth - 640) / 2 + 20;
        setLogoLeftPosition(leftOffset);
      } else {
        setLogoLeftPosition(20);
      }
    };

    calculateLogoPosition();
    window.addEventListener('resize', calculateLogoPosition);

    return () => window.removeEventListener('resize', calculateLogoPosition);
  }, []);

  // Handle scroll to show/hide logo
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      // Show logo only when at the top (with a small threshold)
      if (currentScrollY <= 10) {
        setShowLogo(true);
      } else {
        setShowLogo(false);
      }
    };
  
    // Initial check
    handleScroll();
  
    window.addEventListener('scroll', handleScroll, { passive: true });
  
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (location.state?.showSuccessToast) {
      toast.success('Successfully submitted!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
          background: '#1A1A1A',
          color: '#9eff00',
          borderRadius: '8px',
          border: '1px solid #888D6B'
        }
      });
      
      // Clear the state after showing toast
      history.replace('/', {});
    }
  }, [location.state, history]);

  const handleCreateClick = () => {
    history.push('/create');
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleLogoClick = () => {
    history.push('/');
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <AppContainer>
      <Logo 
          onClick={handleLogoClick}
          show={showLogo}
          leftPosition={logoLeftPosition}
        >
          <LogoImage 
            src={HeaderIcon}
            alt="Qverse Logo"
          />
        </Logo>
        <ContentWrapper>
          <Header />
          <HeroSection />
          <QuestList />
          <CreateButton onClick={handleCreateClick}>
        + Create Your Content
      </CreateButton>
        </ContentWrapper>
      </AppContainer>
      <CreateUniverseModal open={isModalOpen} onClose={handleCloseModal} />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        limit={1}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default Home;