import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ComicHomeButton from 'components/ui/ComicHomeButton';
import API_URL from '../config';


const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  background: #000;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  
  @media (max-width: 600px) {
    width: 100vw;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  max-width: 640px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  position: relative;
  background: #000;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  @media (max-width: 600px) {
    width: 100vw;
    max-width: 100vw;
  }
`;

const VideoSection = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: always;
`;

const VideoPlayer = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
  background: #000;
  
  @media (max-width: 600px) {
    object-fit: cover;
    max-width: 100vw;
    height: 100vh;
  }
`;

// Update the styled components
const VideoInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  z-index: 1;
  padding: 20px;
  background: ${props => props.expanded ?
    'linear-gradient(transparent, rgba(0,0,0,0.95))' :
    'linear-gradient(transparent, rgba(0,0,0,0.9))'};
  transition: all 0.3s ease;
  min-height: ${props => props.expanded ? 'auto' : 'auto'};
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const VideoTitle = styled.h2`
  margin: 0;
  font-size: 1.2rem;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.expanded ? 'unset' : '1'};
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease;
`;

const VideoDescription = styled.p`
  font-size: 0.9rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.expanded ? 'unset' : '1'};
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all 0.3s ease;
`;

const SeekerContainer = styled.div`
  position: absolute;
  bottom: 110px;
  left: 0;
  right: 0;
  padding: 0 20px;
  z-index: 2;
  opacity: ${props => props.hide ? 0 : 1};
  transition: opacity 0.3s ease;
  pointer-events: ${props => props.hide ? 'none' : 'auto'};
`;

const SeekerBar = styled.div`
  width: 100%;
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  cursor: pointer;
  position: relative;
`;

const Progress = styled.div`
  height: 100%;
  background: #fff;
  border-radius: 2px;
  width: ${props => props.width}%;
`;

const IconButton = styled.button`
  position: relative;
  top: 20px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
  transition: background 0.3s ease;
  color: white;

  @media (max-width: 640px) {
    width: 32px;
    height: 32px;
    positon: fixed;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

const VideoControls = styled.div`
  position: absolute;
  bottom: 140px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 2;
  opacity: ${props => props.hide ? 0 : 1};
  transition: opacity 0.3s ease;
  pointer-events: ${props => props.hide ? 'none' : 'auto'};
  
  @media (max-width: 640px) {
    
  }
`;


const PlayPauseOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  animation: fadeOut 0.5s ease-out forwards;

  &.show {
    animation: fadeIn 0.2s ease-in forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.8);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.8);
    }
  }
`;

const PlayPauseIcon = styled.div`
  color: white;
  font-size: 30px;
  transform: ${props => props.isPlaying ? 'scale(0.8)' : 'scale(1)'};
  transition: transform 0.2s ease;
`;



function ShortVideoPlayer() {
  const [videos, setVideos] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRefs = useRef({});
  const { slug } = useParams();
  const history = useHistory();
  const observerRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const seekBarRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isMobile] = useState(window.innerWidth <= 500);
  const [showPlayPause, setShowPlayPause] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const playPauseTimeoutRef = useRef(null);
  const [hasAudio, setHasAudio] = useState(false);
  const [expandedInfo, setExpandedInfo] = useState(false);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${API_URL}/generator/shortvideos/`);
        // Sort videos to maintain consistent order
        const sortedVideos = response.data.sort((a, b) => a.id - b.id);
        setVideos(sortedVideos);

        // Find the index of the video matching the slug
        const index = sortedVideos.findIndex(video => video.slug === slug);

        // Set current index, defaulting to 0 if slug not found
        setCurrentVideoIndex(index >= 0 ? index : 0);

        // If we have a valid index, scroll to that video
        if (index >= 0) {
          setTimeout(() => {
            const container = document.getElementById('video-container');
            if (container) {
              container.scrollTo({
                top: index * container.clientHeight,
                behavior: 'instant'
              });
            }
          }, 100);
        }
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, [slug]);

  // Add this useEffect to handle URL updates
  useEffect(() => {
    if (videos.length > 0 && currentVideoIndex >= 0) {
      history.replace(`/shorts/${videos[currentVideoIndex].slug}`);
    }
  }, [currentVideoIndex, videos]);

  useEffect(() => {
    // Set up Intersection Observer
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const video = entry.target;
          if (entry.isIntersecting) {
            video.play().catch(error => console.log('Autoplay prevented:', error));
          } else {
            video.pause();
            video.currentTime = 0;
          }
        });
      },
      {
        threshold: 0.7,
      }
    );

    // Observe all video elements
    Object.values(videoRefs.current).forEach((videoEl) => {
      if (videoEl) {
        observerRef.current.observe(videoEl);
      }
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [videos]);
  useEffect(() => {
    return () => {
      if (playPauseTimeoutRef.current) {
        clearTimeout(playPauseTimeoutRef.current);
      }
    };
  }, []);

  const handleScroll = (e) => {
    const container = e.target;
    const newIndex = Math.round(container.scrollTop / container.clientHeight);

    if (newIndex !== currentVideoIndex && newIndex < videos.length) {
      setCurrentVideoIndex(newIndex);
    }
  };

  const handleVideoClick = (video) => {
    if (video.paused) {
      video.play().catch(error => console.log('Playback failed:', error));
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }

    // Show play/pause animation
    setShowPlayPause(true);

    // Clear any existing timeout
    if (playPauseTimeoutRef.current) {
      clearTimeout(playPauseTimeoutRef.current);
    }

    // Hide the overlay after 500ms
    playPauseTimeoutRef.current = setTimeout(() => {
      setShowPlayPause(false);
    }, 500);
  };

  // const handleVideoClick = (video) => {
  //   if (video.paused) {
  //     video.play().catch(error => console.log('Playback failed:', error));
  //   } else {
  //     video.pause();
  //   }
  // };

  const toggleMute = (video) => {
    video.muted = !video.muted;
    setIsMuted(video.muted);
  };

  const handleTimeUpdate = (video) => {
    const progress = (video.currentTime / video.duration) * 100;
    setProgress(progress);
    setCurrentTime(video.currentTime);
  };

  // Handle seeking
  const handleSeek = (e, videoElement) => {
    const seekBar = seekBarRef.current;
    const rect = seekBar.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const seekPosition = (x / rect.width) * 100;
    const seekTime = (seekPosition / 100) * videoElement.duration;

    videoElement.currentTime = seekTime;
    setProgress(seekPosition);
  };

  const checkAudio = (video) => {
    if (video.mozHasAudio || // Firefox
      Boolean(video.webkitAudioDecodedByteCount) || // Chrome
      Boolean(video.audioTracks?.length)) { // Safari
      setHasAudio(true);
    } else {
      setHasAudio(false);
    }
  };

  // Handle video loaded metadata
  const handleLoadedMetadata = (video) => {
    setDuration(video.duration);
    checkAudio(video);
  };

  const handleInfoClick = (e) => {
    e.stopPropagation(); // Prevent video play/pause
    setExpandedInfo(!expandedInfo);

  };

  const handleAudioClick = () => {
    // If video has no audio, do nothing on click
    if (!hasAudio) return;

    const video = videoRefs.current[currentVideoIndex];
    if (video) {
      video.muted = !video.muted;
      setIsMuted(video.muted);
    }
  };

  return (
    <PageContainer>
      <div
        onClick={() => history.push('/')}
        style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          borderRadius: '20px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          cursor: 'pointer',
          zIndex: 10,
          maxWidth: 'calc(640px - 40px)', // Ensure it stays within container
          marginLeft: 'auto',
          marginRight: 'auto',
          left: '0',
          right: '0',
          width: 'fit-content',
          transform: isMobile ? 'translateX(215%)' : 'translateX(300%)',
        }}
      >
        <ComicHomeButton text="Home" Icon={HomeRoundedIcon} />
      </div>
      <VideoContainer id="video-container" onScroll={handleScroll}>
        {videos.map((video, index) => (
          <VideoSection key={video.slug}>
          <VideoPlayer
            ref={el => videoRefs.current[index] = el}
            src={video.url}
            playsInline
            loop
            muted={isMuted}
            preload="auto"
            poster={video.thumbnail}
            onClick={(e) => handleVideoClick(e.target)}
            onTimeUpdate={(e) => handleTimeUpdate(e.target)}
            onLoadedMetadata={(e) => handleLoadedMetadata(e.target)}
          />
          <PlayPauseOverlay className={showPlayPause ? 'show' : ''}>
            <PlayPauseIcon isPlaying={isPlaying}>
              {isPlaying ? <PlayArrowIcon style={{ fontSize: 24 }} /> : <PauseIcon style={{ fontSize: 24 }} />}
            </PlayPauseIcon>
          </PlayPauseOverlay>
          <VideoControls hide={expandedInfo}>
            <IconButton onClick={() => toggleMute(videoRefs.current[currentVideoIndex])}>
              {isMuted ? 
                <VolumeOffIcon style={{ fontSize: 24 }} /> : 
                <VolumeUpIcon style={{ fontSize: 24 }} />
              }
            </IconButton>
          </VideoControls>
          <SeekerContainer hide={expandedInfo}>
            <SeekerBar 
              ref={seekBarRef}
              onClick={(e) => handleSeek(e, videoRefs.current[currentVideoIndex])}
            >
              <Progress width={progress} />
            </SeekerBar>
          </SeekerContainer>
          <VideoInfo 
            expanded={expandedInfo}
            onClick={handleInfoClick}
          >
            <VideoTitle expanded={expandedInfo}>
              {video.name}
            </VideoTitle>
            <VideoDescription expanded={expandedInfo}>
              {video.description}
            </VideoDescription>
          </VideoInfo>
        </VideoSection>
        ))}
      </VideoContainer>
    </PageContainer>
  );
}

export default ShortVideoPlayer;