import { createGlobalStyle } from 'styled-components';
import { initializeGA, trackSessionStart, trackSessionEnd } from './utils/analytics';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import Gameplay from './pages/Gameplay';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Home from './pages/Home';
import QuestCompletePage from './components/ui/rewards';
import AudioPage from './pages/AudioPage';
import Travia from './pages/Travia';
import Comic from './pages/Comic';
import ShortVideos from './pages/ShortVideoAll';
import ShortVideoPlayer from './pages/ShortVideosPage';
import CreateUniversePage from './pages/CreateUniversePage';
import { Button, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=Lato:wght@400;700&display=swap');
  
  body {
    margin: 0;
    padding: 0;
    background-color: #121212;
    color: #ffffff;
    font-family: 'Lato', sans-serif;
  }
`;

const queryClient = new QueryClient();

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  // Initialize Google Analytics and track session start/end
  useEffect(() => {
    initializeGA();
    trackSessionStart();

    return () => {
      trackSessionEnd();
    };
  }, []);

  // Track visibility change to handle session start/end when tab is hidden or shown
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        trackSessionEnd();
      } else {
        trackSessionStart();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Handle beforeinstallprompt event for PWA installation
  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  // Trigger the PWA installation prompt
  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        console.log(choiceResult.outcome);
        setDeferredPrompt(null);
        setIsInstallable(false);
      });
    }
  };

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <Content handleInstallClick={handleInstallClick} isInstallable={isInstallable} />
      </QueryClientProvider>
      <GlobalStyle />
    </Router>
  );
}

function Content({ handleInstallClick, isInstallable }) {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const [showBanner, setShowBanner] = useState(isInstallable && isHomePage);

  useEffect(() => {
    // Show the banner only on the homepage and when installable
    if (isInstallable && isHomePage) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [isInstallable, isHomePage]);

  const handleCloseBanner = () => setShowBanner(false);

  return (
    <>
      {/* Installation Banner at the Top */}
      {/* {showBanner && (
        <Box
          sx={{
            background: 'linear-gradient(180deg, #395D6E 0%, #224658 100%)',
            border: '0px solid #3F8EA0',
            color: '#ffffff',
            padding: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            top: 0,  // Align to the top of the screen
            left: '50%',  // Center horizontally
            transform: 'translateX(-50%)',    // Offset by 50% of the width to center it
            width: '100%',
            zIndex: 1000,
            maxWidth: '600px',  // Set max width to 640px
            margin: '0 auto'    // Center the Box horizontally
          }}
        >
          <span class="mr-[0.5rem] text-white font-satoshi text-base font-medium leading-[1.28rem]">
            Install this app for a better experience!
          </span>

          <div
            onClick={handleInstallClick}
            style={{
              marginRight: '0.5rem',
              borderRadius: '1rem',
              backgroundColor: '#3F8D9F',
              padding: '0.75rem',
              cursor: 'pointer', // Optional: to indicate it's clickable
              color: '#fff', // Optional: text color for contrast
              textAlign: 'center' // Optional: to center text within the div
            }}
          >
            <div className="text-center text-[#FFF] font-medium text-[0.875rem] md:text-[1rem] lg:text-[0.875rem] leading-[0.875rem] uppercase font-satoshi ">
              INSTALL APP
            </div>

          </div>

          <div
            onClick={handleCloseBanner}
            className="text-center text-[#FFF] font-normal text-[0.875rem] md:text-[1rem] leading-[1rem] uppercase font-satoshi cursor-pointer"
          >
            Not Now
          </div>

          <IconButton onClick={handleCloseBanner} color="inherit" style={{
            position: 'absolute',
            top: 0,
            right: 0
          }}>
            <CloseIcon sx={{
              fill: '#fff',
              fontSize: {
                xs: '1.1rem', // Mobile
                sm: '1.3rem',   // Small screens
                md: '1.5rem', // Medium screens
                lg: '1.5rem'    // Large screens
              }
            }} />
          </IconButton>
        </Box>
      )} */}

      {/* Routes for Different Pages */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/gameplay/:questId" component={Gameplay} />
        <Route path="/rewards" component={QuestCompletePage} />
        <Route path="/audio/:audioId" component={AudioPage} />
        <Route path="/trivia/:slug" component={Travia} />
        <Route path="/comic/:slug" component={Comic} />
        <Route exact path="/shorts" component={ShortVideos} />
        <Route path="/shorts/:slug" component={ShortVideoPlayer} />
        <Route path="/create" component={CreateUniversePage} />
      </Switch>
    </>
  );
}

export default App;
