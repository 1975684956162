import axios from 'axios';
import React, { useState } from 'react';
import styled from 'styled-components';
import API_URL from '../../config';
import { useHistory } from 'react-router-dom';
import ShareDialog from './ShareButton';
import Tooltip from '@mui/material/Tooltip';
import { styled as muiStyled } from '@mui/material/styles';

const Card = styled.div`
  background-color: transparent; // Remove background color from card
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  // margin: 0;
  margin-top: 1 rem;  
  margin-bottom: 3rem; 
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  position: relative;
`;

// Add tooltip descriptions
const getTooltipContent = (cardType) => {
  switch (cardType) {
    case 'comic':
      return 'Interactive comic stories with choices that matter';
    case 'audio':
      return 'Immersive audio stories with ambient soundscapes';
    case 'trivia':
      return 'Fun and engaging quiz games to test your knowledge';
    case 'video':
      return 'Short-form vertical video content';
    default:
      return 'Interactive story-driven quests';
  }
};
// Custom styled tooltip (optional, for better styling)
const CustomTooltip = muiStyled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    color: 'white',
    fontSize: '12px',
    padding: '8px 12px',
    borderRadius: '6px',
    maxWidth: '200px',
    fontFamily: 'Satoshi, sans-serif'
  }
});

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; // 16:9 aspect ratio
  background: #081f1f; // Slightly darker than card background
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: ${props => props.cardType === 'video' ? 'contain' : 'cover'};
  border-radius: 12px 12px 0 0; // Round only top corners
`;

const PopularTag = styled.span`
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  background: linear-gradient(112deg, #000 0%, rgba(0, 0, 0, 0.50) 100%);
  backdrop-filter: blur(2px);
  color: ${props => {
    switch (props.cardType) {
      case 'comic': return '#FF69B4'; // Pink for comics
      case 'audio': return '#9eff00'; // Green for audio
      case 'trivia': return '#64C7BE'; // Purple for trivia
      default: return '#75FBEF'; // Default color
    }
  }};
  padding: 5px 10px;
  border: 1px solid ${props => {
    switch (props.cardType) {
      case 'comic': return '#FF69B4';
      case 'audio': return '#9eff00';
      case 'trivia': return '#64C7BE';
      default: return '#75FBEF';
    }
  }};
  border-radius: 8px;
  font-size: 17px;
  z-index: 1;
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;

  ${ImageContainer}:hover & {
    opacity: 1;
  }
`;

const Content = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 1.2rem;
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  background: transparent; // Remove background color
`;

const Title = styled.h3`
  font-size: 1.25rem;
  font-family: 'Satoshi', sans-serif;
  color: #ffffff;
  margin: 0;
  line-height: 1.2;
  font-weight: 600;
`;

const Description = styled.p`
  font-size: 0.9rem;
  font-family: 'Satoshi', sans-serif;
  color: #B7B7B7;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4;
  height: 2.8em;
`;

const QuestCard = ({
  title,
  image,
  description,
  players,
  isPopular,
  slug,
  cardType // Add this prop
}) => {

  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);


  const history = useHistory();

  const handleCardClick = () => {
    const paths = {
      comic: '/comic',
      audio: '/audio',
      trivia: '/trivia',
      quest: '/gameplay',
      video: '/shorts'
    };

    history.push({
      pathname: `${paths[cardType]}/${slug}`,
      state: {
        title: title
      }
    });
  };

  const handleShare = async (type) => {
    const shareText = `I just played an AI-generated game of ${title} on Zo.live. Check it out!`;

    switch (type) {
      case 'clipboard':
        try {
          await navigator.clipboard.writeText(shareText);
          // toast.success('Copied to clipboard!', {
          //   position: "top-center",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "dark",
          // });
        } catch (err) {
          console.error('Failed to copy:', err);
        }
        break;

      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`);
        break;

      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`);
        break;
    }
    setIsShareDialogOpen(false);
  };


  return (
    <>
      <Card onClick={handleCardClick}>
        <ImageContainer>
          <Image src={image} alt={title} cardType={cardType} />
          <CustomTooltip 
          title={getTooltipContent(cardType)}
          placement="top"
          arrow
          enterDelay={200}
          leaveDelay={0}
        >
          <PopularTag cardType={cardType}>
            {cardType.charAt(0).toUpperCase() + cardType.slice(1)}
          </PopularTag>
        </CustomTooltip>
          <PlayButton>▶</PlayButton>
        </ImageContainer>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>
      </Card>
      <ShareDialog
        open={isShareDialogOpen}
        onClose={() => setIsShareDialogOpen(false)}
        onShare={handleShare}
      />
    </>
  );
};

export default QuestCard;