import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import QuestCard from './QuestCard';
import API_URL from '../../config';
import axios from 'axios';

const QuestSectionContainer = styled.section`
  padding: 0 1rem;
  max-width: 640px;
  margin-top: 1rem;
  overflow: visible; // Changed from hidden to allow cards to be fully visible
`;

const QuestGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1rem;
`;

const QuestCardWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: ${props => props.isLast ? '80px' : '0'}; // Add margin only to last card
`;

const QuestSection = () => {
  const [allContent, setAllContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllContent = async () => {
      try {
        setLoading(true);
        const [questsResponse, comicsResponse, audioResponse, triviaResponse, shortvideos] = await Promise.all([
          axios.get(`${API_URL}/gameplay/universes/`),
          axios.get(`${API_URL}/generator/comics/`),
          axios.get(`${API_URL}/generator/audiostories/`),
          axios.get(`${API_URL}/generator/trivia/`),
          axios.get(`${API_URL}/generator/shortvideos/`),
        ]);

        // Process all quests into a flat array
        const allQuests = questsResponse.data.universe.reduce((acc, uni) => {
          return [...acc, ...uni.quests.map(quest => ({ ...quest, cardType: 'quest' }))];
        }, []);

        // Process other content
        const comics = comicsResponse.data.map(comic => ({ 
          ...comic, 
          cardType: 'comic' 
        }));

        const audioStories = audioResponse.data.map(audio => ({ 
          ...audio, 
          cardType: 'audio' 
        }));

        const triviaQuizzes = triviaResponse.data.map(trivia => ({ 
          ...trivia, 
          cardType: 'trivia' 
        }));

        const videos = shortvideos.data.map(video => ({ 
          ...video, 
          cardType: 'video' 
        }));



        // Combine and shuffle all content
        const allItems = shuffleArray([
          ...allQuests,
          ...audioStories,
          ...comics,
          ...triviaQuizzes,
          ...videos
        ]);

        // Set all content as a single array
        setAllContent(allItems);
      } catch (error) {
        console.error('Error fetching content:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllContent();
  }, []);

  // Shuffle array helper function
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  if (loading) {
    return (
      <QuestSectionContainer>
        <div style={{ textAlign: 'center', padding: '20px' }}>Loading...</div>
      </QuestSectionContainer>
    );
  }

  if (error) {
    return (
      <QuestSectionContainer>
        <div style={{ textAlign: 'center', padding: '20px', color: 'red' }}>
          Error: {error}
        </div>
      </QuestSectionContainer>
    );
  }

  return (
    <QuestSectionContainer>
      <QuestGrid>
        {allContent.map((item, idx) => (
          <QuestCardWrapper key={`${item.cardType}-${item.id || idx}`}
          isLast={idx === allContent.length - 1} 
          >
            <QuestCard 
              title={item.name}
              image={item.thumbnail}
              description={item.description}
              players={item.playing}
              isPopular={true}
              slug={item.slug}
              cardType={item.cardType}
            />
          </QuestCardWrapper>
        ))}
      </QuestGrid>
    </QuestSectionContainer>
  );
};
export default QuestSection;