import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    backgroundImage: 'url("https://example.com/path-to-your-texture-image.jpg")', // Replace with actual texture image
    backgroundBlendMode: 'overlay',
    color: theme.palette.common.white,
    border: '2px solid #d4af37', // Golden border
    borderRadius: '8px',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: theme.palette.common.white,
  border: '1px solid #d4af37',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const GoldenText = styled(Typography)(({ theme }) => ({
  color: '#d4af37',
  textShadow: '1px 1px 2px rgba(0,0,0,0.7)',
}));

const CharacterInfoPopup = ({ open, onClose, characters }) => {
  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
        <GoldenText variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
          Characters of the Realm
        </GoldenText>
        <Grid container spacing={3}>
          {characters?.map((character, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <StyledCard>
                <CardMedia
                  component="img"
                  height="250"
                  image={character.image}
                  alt={character.name}
                />
                <CardContent>
                  <GoldenText variant="h6" gutterBottom>
                    {character.name}
                  </GoldenText>
                  <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    {character.role}
                  </Typography>
                  <Typography variant="body2">
                    {character.description || "A mysterious figure in this realm."}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};
 
export default CharacterInfoPopup;