import { useEffect,useState,useRef } from 'react';
import CenteredBox from 'components/ui/Layouts/CenteredBox';
import ComicPage from 'components/ui/ComicPage';
import axiosWrapper from 'utils/helper/axiosWrapper';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Loader from 'components/ui/Loader';

const Comic = () => {
  const DarkOverlay = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.70) 100%)',
    zIndex: 1,
  });
  
  const { slug } = useParams();
  const [comicPages, setComicPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState(null);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const result = await axiosWrapper.get(`comics/${slug}/`);
        setComicPages(result?.pages);
        setTitle(result?.comic?.name);
      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  return (
    <>
    {loading && (<Loader text={"Comic..."} />)}
    { !loading &&<CenteredBox>
      
      <div className="relative h-screen w-full bg-black">
        {/* Main container with hidden scrollbar */}
        <div 
          ref={scrollContainerRef}
          className="h-full w-full overflow-y-scroll snap-y snap-mandatory scrollbar-hide"
        >
          {comicPages?.map((page) => (
            <ComicPage 
              key={page.id}
              image={page} 
              title={title}
              scrollParent={scrollContainerRef.current}
              pageCount={comicPages?.length}
            />
          ))}
        </div>
        
        {/* Add custom CSS to hide scrollbar for all browsers */}
        <style jsx global>{`
          .scrollbar-hide::-webkit-scrollbar {
            display: none;
          }
          .scrollbar-hide {
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
        `}</style>
      </div>
    </CenteredBox>}
    </>
  );
};

export default Comic;
