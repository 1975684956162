// src/utils/analytics.js
import ReactGA from "react-ga4";
import { v4 as uuidv4 } from 'uuid';

const SESSION_STORAGE_KEYS = {
    SESSION_START: 'session_start_time',
    SESSION_ID: 'session_id',
    VISIT_COUNT: 'visit_count'
};

// Initialize GA with your measurement ID
export const initializeGA = () => {
    ReactGA.initialize("G-P9GYQLK0HC", {
        gtagOptions: {
            send_page_view: true,
            session_duration: true,
            // Enable session tracking
            session_engaged: true,
            // Track engaged time
            engagement_time_msec: true
        }
    });
};


// Track session start with enhanced session management
export const trackSessionStart = () => {
    const sessionId = uuidv4(); // Generate UUID
    const startTime = Date.now();

    // Store session data
    sessionStorage.setItem(SESSION_STORAGE_KEYS.SESSION_ID, sessionId);
    sessionStorage.setItem(SESSION_STORAGE_KEYS.SESSION_START, startTime.toString());

    // Track visit count
    const visitCount = parseInt(localStorage.getItem(SESSION_STORAGE_KEYS.VISIT_COUNT) || '0');
    localStorage.setItem(SESSION_STORAGE_KEYS.VISIT_COUNT, (visitCount + 1).toString());

    // Send session start event to GA4
    ReactGA.event({
        category: "Session",
        action: "Start",
        label: `Visit #${visitCount + 1}`,
        sessionId: sessionId,
        non_interaction: true,
        session_engaged: true
    });

    // Set session-scoped custom dimensions
    ReactGA.gtag('set', {
        'session_id': sessionId,
        'visit_number': visitCount + 1
    });
};

// Track session end with duration
export const trackSessionEnd = () => {
    const sessionId = sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_ID);
    const startTime = parseInt(sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_START) || '0');

    if (sessionId && startTime) {
        const duration = (Date.now() - startTime) / 1000; // Convert to seconds

        ReactGA.event({
            category: "Session",
            action: "End",
            label: `Duration: ${Math.round(duration)}s`,
            value: Math.round(duration),
            sessionId: sessionId,
            non_interaction: true
        });

        // Clear session storage
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.SESSION_ID);
        sessionStorage.removeItem(SESSION_STORAGE_KEYS.SESSION_START);
    }
};

// Track game start
export const trackGameStart = (questId, gameTitle) => {
    const sessionId = sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_ID);
    const visitCount = parseInt(localStorage.getItem(SESSION_STORAGE_KEYS.VISIT_COUNT) || '0');

    ReactGA.event({
        category: "Game",
        action: "Start",
        label: gameTitle,
        value: 1,
        questId: questId,
        sessionId: sessionId,
        visitNumber: visitCount
    });
};


export const trackGameComplete = (questId, gameTitle, completionData) => {
    const {
        timeSpent,
        categoryScores,
        totalQuestions,
        rewardsEarned,
        timestamp,
        timeZone,
        questVersion
    } = completionData;

    // Track overall completion
    ReactGA.event({
        category: "Game",
        action: "Complete",
        label: gameTitle,
        timestamp,
        timeZone,
        questId,
        timeSpent,
        totalQuestions,
        rewardsEarned,
        questVersion
    });

    // Track each category score separately
    categoryScores.forEach(({ category, score, maxPossible }) => {
        ReactGA.event({
            category: "Game Score",
            action: "Category Score",
            label: category,
            value: score, // The actual score
            questId,
            maxPossible,
            scorePercentage: (score / maxPossible) * 100,
            gameTitle
        });
    });
};


// Track time spent
export const trackTimeSpent = (timeInSeconds, pageId) => {
    const sessionId = sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_ID);

    ReactGA.event({
        category: "Engagement",
        action: "Time Spent",
        label: pageId,
        value: Math.round(timeInSeconds),
        sessionId: sessionId,
        metric_id: "time_spent",
        metric_value: timeInSeconds
    });
};


export const trackQuestProgress = (questId, progressData) => {
    const {
        progressPercentage,
        currentQuestionNo,
        totalQuestions,
        categoryScores
    } = progressData;

    // Track overall progress
    ReactGA.event({
        category: "Quest",
        action: "Progress",
        label: questId,
        value: Math.round(progressPercentage),
        currentQuestion: currentQuestionNo,
        totalQuestions
    });

    // Track category progress
    categoryScores.forEach(({ category, currentScore }) => {
        ReactGA.event({
            category: "Category Progress",
            action: "Update",
            label: category,
            value: currentScore,
            questId,
            questionNumber: currentQuestionNo
        });
    });
};

// Add this new function to track category-specific events
export const trackCategoryEvent = (category, action, value, additionalParams = {}) => {
    ReactGA.event({
        category: "Category Interaction",
        action,
        label: category,
        value,
        ...additionalParams
    });
};
// Track user interactions
export const trackUserInteraction = (action, label, value = null) => {
    const sessionId = sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_ID);

    ReactGA.event({
        category: "User Interaction",
        action,
        label,
        ...(value && { value }),
        sessionId: sessionId
    });
};

// Set user properties
export const setUserProperties = (properties) => {
    ReactGA.gtag('set', 'user_properties', {
        ...properties,
        session_id: sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_ID),
        visit_count: localStorage.getItem(SESSION_STORAGE_KEYS.VISIT_COUNT)
    });
};